import { Component } from "react";
import styles from "./Skills.module.scss";

export default class Skills extends Component {

    render() {
        return (
            <div className={styles.globalSkills}>
                <p className={styles.globaltitle}>Skills:</p>
                <div className={styles.skills}>
                    <div className={styles.content}>
                        <p className={styles.description}>В разработке</p>
                    </div>
                </div>
            </div>
        )
    }
}