import { Component } from "react";
import { Tooltip } from "../index";
import styles from "./Info.module.scss";

const badges = [
    'https://i.imgur.com/fvPvvm0.png',
    // 'https://i.imgur.com/nWKPJey.png',
    // 'https://i.imgur.com/78QBbLr.png',
    'https://i.imgur.com/ycx4neK.png',
    'https://i.imgur.com/uBfPzrk.png',
    'https://i.imgur.com/IylMFh9.png'
]

export default class Info extends Component {

    render() {
        return (
            <div className={styles.info}>
                <div className={styles.image}>
                    {
                        this.props.isProfile ? (
                            <svg width="76" height="76" viewBox="0 0 120 120" xmlns="http://www.w3.org/2000/svg">
                                <mask id="avatarMask" maskContentUnits="objectBoundingBox" viewBox="0 0 1 1">
                                    <circle fill="white" cx="0.5" cy="0.5" r="0.5"></circle>
                                    <circle fill="black" cx="0.8333333333333334" cy="0.8333333333333334" r="0.16666666666666666"></circle>
                                </mask>
                                <image
                                    width="120"
                                    height="120"
                                    href={this.props.image}
                                    mask="url(#avatarMask)"
                                />
                                <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" x="88" y="88">
                                    <mask id="statusMask" maskContentUnits="objectBoundingBox" viewBox="0 0 1 1">
                                        <circle fill="white" cx="0.5" cy="0.5" r="0.5"></circle>
                                        <circle fill="black" cx="0.25" cy="0.25" r="0.375"></circle>
                                    </mask>
                                    <rect
                                        width="24"
                                        height="24"
                                        fill="#f0b232"
                                        mask="url(#statusMask)"
                                    />
                                </svg>
                            </svg>
                        ) : (
                            <img alt='file' src={this.props.image} />
                        )
                    }
                </div>
                <div className={styles.content}>
                    <p className={styles.title}>
                        { this.props.title }
                    </p>
                    {this.props.isProfile && (
                        <div className={styles.badges}>
                            {badges.map((badge, index) => (
                                <img
                                    key={index}
                                    className={styles.badge}
                                    src={badge}
                                    alt={`Badge ${index}`}
                                />
                            ))}
                        </div>
                    )}
                    <Tooltip text='Скопировано!'>
                        <p
                            className={styles.description}
                            onClick={
                                this.onCopy.bind(
                                    this, (
                                        this.props?.isProfile ? this.props.description
                                            : `${this.props.description} - ${this.props.title}`
                                    )
                                )
                            }
                        >
                            { this.props.description }
                        </p>
                    </Tooltip>
                </div>
            </div>
        )
    }

    onCopy(text) {
        return navigator.clipboard.writeText(text)
    }
}